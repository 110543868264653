import * as Sentry from "@sentry/remix"
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from "@remix-run/react"
import { startTransition, StrictMode, useEffect } from "react"
import { hydrateRoot } from "react-dom/client"

Sentry.init({
  dsn: window.ENV.SENTRY_DSN,
  enabled:
    window.ENV.APP_ENV === "production" || window.ENV.APP_ENV === "staging",
  tracesSampleRate: window.ENV.SENTRY_TRACE_RATE,
  replaysSessionSampleRate: window.ENV.SENTRY_REPLAY_SESSION_RATE,
  replaysOnErrorSampleRate: window.ENV.SENTRY_REPLAY_ERROR_RATE,
  environment: window.ENV.APP_ENV,
  release: window.ENV.RELEASE || "dev",
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration(),
  ],
})

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  )
})
